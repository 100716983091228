.tableFormItem {
  margin-bottom: 0;

  :global {

    .ant-form-item-explain,
    .ant-form-item-extra {
      min-height: 0;
    }
  }

}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
